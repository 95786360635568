import React, { HTMLAttributes } from 'react';
import './heading.scss';
/* eslint-disable-next-line */

export interface HeadingProps extends HTMLAttributes<HTMLHeadElement> {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | string;
  children: React.ReactNode;
  ariaLevel?: string;
  isOnHomeBanner?: boolean;
  className?: string;
}

/**
 * This component accepts configuration based on the parent component invoking it. The component configures the heading tag based on the requirement of the block that uses this.
 * @param props
 * @returns template of heading
 */
export function Heading(props: HeadingProps) {
  const {
    type = 'h2',
    children,
    ariaLevel,
    isOnHomeBanner = false,
    ...rest
  } = props;
  const elements = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6'
  };
  const isValidVarient = Object.values(elements).includes(type);
  const tag = type?.toLowerCase() as keyof typeof elements;
  const headingTag = isValidVarient ? elements[tag] : elements.h2;
  let { className } = rest;
  const role = 'heading';
  const arialevel = ariaLevel || '1';
  const fontStyles = '!font-frutiger font-bold set-frutiger';

  switch (headingTag) {
    case 'h1':
      if (isOnHomeBanner) className += ` t-46-64 ${fontStyles}`;
      else className += ` t-46-54 ${fontStyles}`;
      break;
    case 'h2':
      if (isOnHomeBanner) className += ` t-46-54 ${fontStyles}`;
      else className += ` t-33-46 ${fontStyles}`;
      break;
    case 'h3':
      if (isOnHomeBanner) className += ` t-30-46 ${fontStyles}`;
      else className += ` t-25-36 ${fontStyles}`;
      break;
    case 'h4':
      if (isOnHomeBanner) className += ` t-25-36 ${fontStyles}`;
      else className += ` t-22-28 ${fontStyles}`;
      break;
    case 'h5':
      if (isOnHomeBanner) className += ` t-22-28 ${fontStyles}`;
      else className += ` t-18-21 ${fontStyles}`;
      break;
    case 'h6':
      if (isOnHomeBanner) className += ` t-18-21 ${fontStyles}`;
      else className += ` t-16-17 font-bold`;
      break;
  }
  return React.createElement(
    headingTag,
    { ...rest, className, role, 'aria-level': arialevel },
    children
  );
}
