export interface IconProps {
  iconName: string;
  className?: string;
}

/**
 * This component is used to render SVG icons from the bayada-icons.svg sprite
 * The iconName prop should match one of the id's of the svg symbol elements inside the svg file
 * The className prop is optional and can be used to apply additional styles to the svg element
 */
export function Icon(props: IconProps) {
  const { iconName, className } = props || {};
  const iconUrl = `/icons/bayada-icons.svg#${iconName}`;

  return (
    <svg className={`svg-icon ${className ?? ''}`}>
      <use xlinkHref={iconUrl}></use>
    </svg>
  );
}

export default Icon;
