export interface ISocialMediaShareLinks {
  [key: string]: string;
}

const socialMediaShareLinks: ISocialMediaShareLinks = {
  facebook: 'https://www.facebook.com/sharer.php?u=',
  twitter: 'https://twitter.com/share?&url=',
  linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  reddit: 'https://www.reddit.com/submit?url=',
  whatsapp: 'https://api.whatsapp.com/send?text=',
  pinterest: 'https://pinterest.com/pin/create/button/?url='
};

export function getSocialShareLinks(
  platform: string,
  shareUrl: string
): string | null {
  const encodedShareUrl = shareUrl ?? encodeURIComponent(shareUrl);

  if (!platform || !(platform in socialMediaShareLinks)) {
    return null;
  }

  return `${socialMediaShareLinks[platform]}${encodedShareUrl}`;
}
