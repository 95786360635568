'use client';
import React, { useEffect, useState } from 'react';
// import styles from './dropdown-button.module.scss';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@mui/material';
import Icon from '../icon/ba-icon';

/* eslint-disable-next-line */
export interface DropdownButtonProps {
  triggerClassName?: string;
  textClassName?: string;
  buttonIcon?: React.ReactNode;
  iconStart?: boolean;
  sx?: React.CSSProperties;
  items?: DropDownOption[];
  onSelect: (option: DropDownOption) => void;
}

export interface DropDownOption {
  label: string;
  value: string;
  active: boolean;
  id?: string;
}

/**
 * DropdownButton component.
 * This component renders a dropdown menu with a button that opens the menu.
 * When the menu is opened, it will capture all clicks outside of it to close the menu.
 * When the menu is closed, it will return focus to the button.
 *
 * Props:
 * - triggerClassName: className of the trigger button
 * - buttonIcon: icon for the trigger button
 * - iconStart: boolean to determine if the icon should appear on the left or right of text
 * - sx: style object for the trigger button
 * - items: list of dropdown menu options
 * - onSelect: callback function when an item is selected
 */
export function DropdownButton(props: DropdownButtonProps) {
  const {
    triggerClassName,
    buttonIcon,
    iconStart,
    sx,
    items = [],
    onSelect
  } = props;
  const [open, setOpen] = React.useState(false);
  const [sortOptions, setSortOptions] = useState<DropDownOption[]>(items);
  const activeOption = items?.find((option) => option?.active === true);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setSortOptions(items);
  }, [items]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * Handle the close of the dropdown menu.
   * Checks if the event target is contained within the trigger button.
   * If it is, the dropdown menu will remain open. If not, the dropdown menu will close.
   */
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // A function to handle keydown events for a list.
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  /**
   * Handle the onClick event for a dropdown option.
   * When the dropdown option is clicked, we update the state of the
   * dropdown options by setting the clicked option to active and
   * all other options to inactive. Then we close the dropdown by setting
   * `open` to false. Finally, we call the `onSelect` prop function passing
   * the clicked option object as an argument.
   */
  const handleDropdownOptionOnClick = (option: DropDownOption) => {
    const currentState = [...sortOptions]?.map((item) => {
      if (item?.id === option?.id) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setSortOptions(currentState);
    setOpen(false);
    onSelect(option);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="dropdown-button-wrapper" style={{ zIndex: 1 }}>
      <Button
        variant="text"
        color="secondary"
        disableFocusRipple
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`menu-dropdown ${triggerClassName}`}
        startIcon={iconStart ? buttonIcon : null}
        // endIcon={!iconStart ? buttonIcon : null}
        style={{
          ...sx
        }}
        endIcon={
          <Icon
            iconName="chevronDown"
            className="svg-icon icon-12 flex items-center justify-center"
          ></Icon>
        }
      >
        <span className="t-13 font-frutiger font-bold normal-case leading-[1]">
          {activeOption?.label}
        </span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  className="app-menu-list"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {sortOptions?.map((option) => {
                    return (
                      <MenuItem
                        key={option?.id}
                        onClick={() => {
                          handleDropdownOptionOnClick(option);
                        }}
                        className="app-menu-list-items"
                      >
                        {option?.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default DropdownButton;
