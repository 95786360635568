'use client';
import { Container } from '@mui/material';
import './alert.scss';
import Icon from '../icon/ba-icon';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import CtaButton, { CtaButtonProps } from '../cta-button/cta-button';
import { Sys } from '@bayada/interfaces';
/**
 * This component provides a flexible and customizable alert system that can be easily integrated into a larger application, with options for different styles, layouts, and behaviors.
 */
export interface AlertProps {
  fixed?: boolean;
  containerClassName?: string;
  alertBodyClassName?: string;
  varient?: 'primary' | 'secondary' | 'default';
  showCloseBtn?: boolean;
  expirationDate?: string;
  text?: string;
  cta?: CtaButtonProps | null;
  internalName?: string;
  sys?: Sys;
  textClass?: string;
}
// cta button styles for defualt type alert
const defaultAlertCtaSx = {
  '&.button-small': {
    padding: 0
  },
  minWidth: '0',
  height: 'auto',
  minHeight: '0px!important',
  marginLeft: '4px',
  border: 'none',
  flexShrink: 0,
  color: 'var(--primary-shade)',
  textDecoration: 'underline',
  '&:hover': {
    color: 'var(--primary-shade)',
    backgroundColor: 'transparent'
  },
  '&:focus': {
    outline: 'solid 2px',
    color: 'var(--ba-primary-black)',
    backgroundColor: 'transparent'
  }
};
// cta button styles for primary type alert
const primaryAlertCtaSx = {
  border: '1px solid var(--white)',
  padding: '8px 14px',
  height: '34px',
  width: 'max-content',
  marginLeft: '20px',
  flexShrink: 0,
  background: 'var(--primary-shade)',
  color: 'var(--white)',
  '&:hover': {
    color: 'var(--primary-shade)',
    background: 'var(--white)'
  }
};

export const Alert = memo((props: AlertProps) => {
  const {
    containerClassName = '',
    varient = 'primary',
    showCloseBtn = true,
    fixed,
    alertBodyClassName = '',
    expirationDate,
    cta,
    text,
    textClass
  } = props || {};
  const isAlertExpired = new Date() > new Date(expirationDate ?? '');
  const [showAlert, setAlertVisibility] = useState<boolean>(!isAlertExpired);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      varient !== 'default' &&
      showCloseBtn &&
      !document.body.classList.contains('alerted')
    ) {
      document.body.classList.add('alerted');
    }

    const updateBodyPadding = () => {
      if (parentRef.current) {
        const alertHeights = parentRef.current.offsetHeight;
        const footer = document.querySelector('.app-footer') as HTMLElement;
        if (footer && document.body.classList.contains('alerted')) {
          footer.style.paddingBottom = `${alertHeights}px`;
        }
      }
    };
    updateBodyPadding();
    window.addEventListener('resize', updateBodyPadding);
    return () => {
      document.body.classList.remove('alerted');
      window.removeEventListener('resize', updateBodyPadding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertVarient =
    varient === 'primary'
      ? 'alert-primary'
      : varient === 'secondary'
        ? 'alert-secondary'
        : 'alert-default';

  /* The `AlertComponent` constant is a functional component created using the `useCallback` hook in
React. This component represents the structure and content of the alert that will be displayed. It
includes JSX elements for the alert message, close button, and any call-to-action button that may be
provided. */
  const AlertComponent = useCallback(() => {
    return (
      <div
        className={`alert-wrapper flex flex-1 ${varient === 'default' ? '' : 'py-2 '}`}
        role="alert"
      >
        <div
          className={`${alertBodyClassName ?? 'items-center justify-start  sm:justify-between md:justify-start py-3'} alert-body flex-1 ${varient === 'default' ? 'whitespace-nowrap' : 'flex justify-between lg:justify-start items-center '}`}
        >
          <p
            className={`font-inter inline  ${textClass ? textClass : `t-14-15`}`}
          >
            {text}
          </p>
          {cta && (
            <CtaButton
              {...cta}
              hyperlinkClass="inline-block"
              className="shrink-0"
              sx={varient === 'default' ? defaultAlertCtaSx : primaryAlertCtaSx}
              textClass="t-13"
            />
          )}
        </div>
        {showCloseBtn && varient !== 'default' && (
          <div className="alert-close-btn pl-4 flex">
            <button
              type="button"
              name="alert-close-button"
              aria-label="Close Alert"
              tabIndex={0}
              onClick={() => {
                setAlertVisibility(false);
                const footer = document.querySelector(
                  '.app-footer'
                ) as HTMLElement;
                footer.style.paddingBottom = `0px`;
                const appDrawer = document.querySelector(
                  '.app-menu-body'
                ) as HTMLElement;
                appDrawer.style.paddingBottom = `0px`;
                if (document.body.classList.contains('alerted'))
                  document.body.classList.remove('alerted');
              }}
            >
              <Icon
                iconName="close"
                className="svg-icon icon-20 flex items-center justify-center mt-2"
              ></Icon>
            </button>
          </div>
        )}
      </div>
    );
  }, [alertBodyClassName, cta, showCloseBtn, text, varient, textClass]);

  if (showAlert) {
    return (
      <div
        ref={parentRef}
        style={fixed ? { position: 'fixed' } : {}}
        className={`alert-container inline-flex ${alertVarient}`}
      >
        {varient === 'default' ? (
          <div className={containerClassName}>
            <AlertComponent />
          </div>
        ) : (
          <Container fixed className={`test {containerClassName}`}>
            <AlertComponent />
          </Container>
        )}
      </div>
    );
  }
});

export default Alert;
