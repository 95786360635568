/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Icon } from '../../../../../../libs/shared/ui-components/src';

export type selectBox = {
  fieldLabel?: string;
  className?: string;
  variant: any;
  onSelect?: (event: SelectChangeEvent) => void;
  labelClass?: string;
  data?: selectItemProps[];
  formClass?: string;
  defaultValue?: string;
};

export interface selectItemProps {
  text: string;
  value: string;
  sysId: string;
}

/**
 * The ChevronUp component
 * Renders a downward chevron icon that is used in the SelectBox component
 * The icon is rotated 180 degrees (upside down) to indicate that the select box is open
 *
 * @returns {JSX.Element} The ChevronUp component
 */
function ChevronUp() {
  return (
    <Icon
      iconName="chevronDown"
      className="svg-icon icon-12 text-primary-black transition rotate-180"
    ></Icon>
  );
}

/**
 * The SelectBox component
 * Renders a select box with a downward chevron icon
 * Handles scrolling on the page to close the select box when the user scrolls
 *
 * @param {object} props
 * @param {string} props.fieldLabel - The label for the select box
 * @param {string} props.className - The className for the SelectBox component
 * @param {string} props.variant - The variant for the FormControl component wrapping the Select component
 * @param {string} props.labelClass - The className for the label of the Select component
 * @param {function} props.onSelect - The function to run when the user selects an option
 * @param {array} props.data - The options for the select box
 */
export const SelectBox: React.FC<selectBox> = ({
  fieldLabel,
  className,
  variant,
  labelClass,
  onSelect,
  data,
  formClass,
  defaultValue
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(
    ''
  );

  React.useEffect(() => {
    const handleScroll = () => {
      setOpen(false); // Close the Select when scrolling
    };

    // Add scroll event listener to the document
    document.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup: remove scroll event listener
      document.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  React.useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
    if (onSelect) {
      onSelect(event);
    }
  };

  return (
    <div className={className}>
      <FormControl
        variant={variant}
        className={`app-form-control ${formClass}`}
      >
        <InputLabel
          id="select-field-label"
          htmlFor="select-field"
          className={`select-input-label ${labelClass} pointer-events-none`}
        >
          {fieldLabel}
        </InputLabel>
        <Select
          className="app-form-select"
          labelId="select-field-label"
          id="select-field"
          open={open}
          onChange={handleChange}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          inputProps={{ 'aria-labelledby': 'select-field' }}
          value={selectedValue ?? ''}
          MenuProps={{
            onClose: () => setOpen(false) // Close the Select when Menu is closed
          }}
          IconComponent={() => (
            <div className="absolute top-0 right-0 h-full flex items-center justify-center z-0 pointer-events-none min-w-[30px] pr-4">
              {!open ? (
                <div onClick={() => setOpen(true)}>
                  <Icon
                    iconName="chevronDown"
                    className="svg-icon icon-12 text-ba-primary-black transition"
                  ></Icon>
                </div>
              ) : (
                ChevronUp()
              )}
            </div>
          )}
        >
          {data?.map((menuItem) => (
            <MenuItem value={menuItem?.value} key={menuItem?.sysId}>
              <span className="t-15">{menuItem?.text}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default SelectBox;
