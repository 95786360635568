import { SxProps } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';

export interface WrapperContainerProps {
  wrapper?: boolean;
  className?: string;
  children: React.ReactNode;
  sx?: SxProps;
  style?: React.CSSProperties;
  containerCoverClass?: string;
}

/**
 * A wrapper container component that wraps its children in a MUI
 * Container component with some default styles.
 */
export const WrapperContainer = (props: WrapperContainerProps) => {
  const {
    wrapper,
    className = '',
    children,
    sx,
    style,
    containerCoverClass
  } = props || {};
  if (wrapper) {
    if (containerCoverClass) {
      return (
        <div
          className={`flex flex-col w-full cover ${containerCoverClass ?? ''}`}
        >
          <Container
            className={`${className ?? ''} mx-auto`}
            fixed
            sx={sx ? sx : {}}
            style={style ? style : {}}
          >
            {children}
          </Container>
        </div>
      );
    }
    return (
      <Container
        className={`${className ?? ''} mx-auto`}
        fixed
        sx={sx ? sx : {}}
        style={style ? style : {}}
      >
        {children}
      </Container>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

/**
 * Retrieves the wrapper container and class properties from the given props object.
 *
 * @param {Object} props - The props object containing the wrapper container and class properties.
 * @returns {{wrapperContainer?: boolean, wrapperClassName?: string}} - An object containing the wrapper container and class properties.
 */
export const getWrapperProps = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}): {
  wrapperContainer?: boolean;
  wrapperClassName?: string;
} => {
  // Destructure the wrapperContainer and wrapperClassName properties from the props object.
  // If they are not present, set them to empty string and false respectively.
  const { wrapperClassName = '', wrapperContainer = false } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props as any) || {};

  // Return the wrapperContainer and wrapperClassName properties.
  return { wrapperContainer, wrapperClassName };
};

export default WrapperContainer;
