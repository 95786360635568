'use client';

import { HubspotProvider } from 'next-hubspot';
import PropTypes from 'prop-types';
import Lenis from '@studio-freight/lenis';
import { useLayoutEffect, useState } from 'react';
import './layout-provider.scss';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { breakpoints } from '@bayada/assets';

export type FormProps = {
  children: React.ReactNode;
};

/**
 * The LayoutProvider component is a wrapper component that sets properties for smooth
 * scrolling and provides a way to encapsulate Hubspot forms within its children.
 * The component is responsible for setting up Lenis, a smooth scrolling library,
 * and provides a way to manage Hubspot forms within the encapsulated components.
 *
 * The component uses two main properties: deviceWidth and lenisInstance. The deviceWidth
 * is used to determine when to initialize and destroy Lenis. This component listens for
 * ResizeObserver changes to the documentElement width and updates the deviceWidth state
 * accordingly. When the deviceWidth is greater than 1200px and the lenisInstance is null,
 * this component initializes Lenis. When the deviceWidth is less than or equal to 1200px
 * and the lenisInstance is not null, this component destroys Lenis.
 *
 * The lenisInstance is the initialized Lenis object. This component listens for scroll
 * events from Lenis and passes them to GSAP's ScrollTrigger.update function. The
 * requestAnimationFrame function is used to call the Lenis raf function to allow smooth
 * scrolling. The gsap.ticker.lagSmoothing function is called with a value of 0 to disable
 * the default GSAP lag smoothing.
 *
 * The component returns a HubspotProvider component that wraps its children. The
 * HubspotProvider component is provided by the next-hubspot package and provides
 * the necessary context and objects to manage Hubspot forms.
 */
export const LayoutProvider: React.FC<FormProps> = ({ children }) => {
  const [deviceWidth, setDeviceWidth] = useState<number>(0);
  const [lenisInstance, setLenisInstance] = useState<Lenis | null>(null);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setDeviceWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(document.documentElement);
  }, []);

  useLayoutEffect(() => {
    if (deviceWidth > Number(breakpoints.lg) && lenisInstance === null) {
      const lenis = new Lenis({
        lerp: 0.11,
        duration: 0.09,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        smoothWheel: true,
        wheelMultiplier: 2,
        syncTouch: true
      });
      lenis.on('scroll', ScrollTrigger.update);
      setLenisInstance(lenis);
    } else if (
      deviceWidth <= Number(breakpoints.lg) &&
      lenisInstance !== null
    ) {
      lenisInstance.destroy();
      setLenisInstance(null);
    }
    function raf(time: number) {
      lenisInstance?.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    gsap.ticker.lagSmoothing(0);
  }, [deviceWidth, lenisInstance]);
  return <HubspotProvider>{children}</HubspotProvider>;
};

LayoutProvider.propTypes = { children: PropTypes.node.isRequired };
export default LayoutProvider;
