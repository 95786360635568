/* This TypeScript code snippet is defining various constants related to API URLs, keys, and metadata
for this application. Here's a breakdown of what each part of the code is doing: */

import { Metadata } from 'next';

// Office API
export const OfficeApibaseURL = (
  process.env.OFFICE_API_BASE_URL ?? process.env.NEXT_PUBLIC_OFFICE_API_BASE_URL
)?.replace(/\/$/, '');

// Office direction Url
export const directionUrl = `https://www.google.com/maps/dir/?api=1&origin={origin}&destination={destinationLat},{destinationLng}`;

// bayada base url
export const baseUrl = (
  process.env.BASE_URL ?? process.env.NEXT_PUBLIC_BASE_URL
)?.replace(/\/$/, '');

// Define the URL for the home page
export const homePageUrl = '/';

// Google Maps API Key
export const GoogleMapApiKey =
  process?.env?.GOOGLE_MAP_API_KEY ??
  process?.env?.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;

// Google Site Search Key
export const cxKey =
  process?.env?.GOOGLE_SITE_SEARCH_KEY ||
  process?.env?.NEXT_PUBLIC_GOOGLE_SITE_SEARCH_KEY;

// Google Analytics Key
export const GA_TRACKING_ID =
  process.env.GA_TRACKING_ID || process.env.NEXT_PUBLIC_GA_TRACKING_ID;
// export const GA_TRACKING_ID = 'GTM-NKX2HVKD';

// HubSpot Portal ID
export const portalId =
  process.env.HUBSPOT_PORTAL_ID ?? process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID;

// Contentful SpaceID
export const ctfSpaceId =
  process.env.CONTENTFUL_SPACEID ?? process.env.NEXT_PUBLIC_CONTENTFUL_SPACEID;

// Contentful Access Token
export const ctfAccessToken =
  process.env.CONTENTFUL_ACCESS_TOKEN ??
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;

// Contentful Preview API Access Token
export const ctfPreviewToken =
  process.env.CONTENTFUL_PREVIEW_TOKEN ??
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN;

// Contentful Environment
export const ctfEnvironment =
  process.env.CONTENTFUL_ENVIRONMENT ??
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;

// Office Radius
export const officeRadius =
  process.env.OFFICE_RADIUS ?? process.env.NEXT_PUBLIC_OFFICE_RADIUS;

// Defualt Metadata for fallback
export const defualtMetadata: Metadata = {
  title: `BAYADA Home Health Care`,
  description: `BAYADA is accredited by Community Health Accreditation Partner for meeting the industry’s highest nationally recognized standards of care.`
};

export const bayadaOfficeLocationTitle = 'BAYADA Office Location';
