/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { forwardRef, useState } from 'react';
import Chip from '@mui/material/Chip';

export interface CustomChipProps {
  label: string;
  icon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  className?: string;
  bg?: string; // setting new background color
  sx?: React.CSSProperties;
  chipValue?: string;
  handleChipClick?: (chipValue: string | undefined) => void;
  isClicked?: boolean;
  isMultiSelect?: boolean;
}

const handleClose = () => {};

/**
 * The CustomChip component is a customizable chip component
 * that can be used in different parts of the app. It has a few
 * props that can be used to customize its appearance and behavior.
 *
 * @param label The label of the chip
 * @param icon The icon to be displayed on the chip (optional)
 * @param className The class name of the chip (optional)
 * @param bg The background color of the chip (optional)
 * @param sx The style object that will be applied to the chip (optional)
 */

export const CustomChip = forwardRef(
  (
    {
      label,
      icon,
      className,
      bg,
      sx,
      chipValue,
      isClicked,
      handleChipClick,
      isMultiSelect
    }: CustomChipProps,
    ref: any
  ) => {
    const [clicked, setClicked] = useState(isClicked);

    const handleClick = () => {
      if (isClicked) {
        setClicked(false);
      } else {
        setClicked(!clicked);
      }
      isClicked = !isClicked;
    };

    React.useImperativeHandle(ref, () => ({
      setClicked: setClicked
    }));

    return (
      <Chip
        label={label}
        variant="outlined"
        onClick={() => {
          handleClick();
          handleChipClick?.(chipValue);
        }}
        className={className}
        style={{
          backgroundColor:
            (!isMultiSelect && isClicked) ||
            (isMultiSelect && (clicked || isClicked))
              ? 'var(--ba-green-300)'
              : bg
                ? bg
                : 'inherit',
          borderColor:
            (!isMultiSelect && isClicked) ||
            (isMultiSelect && (clicked || isClicked))
              ? 'var(--ba-green-300)'
              : bg
                ? bg
                : 'inherit',
          color:
            (!isMultiSelect && isClicked) ||
            (isMultiSelect && (clicked || isClicked))
              ? '#FFF'
              : 'inherit',
          cursor: 'pointer',
          ...sx
        }}
        deleteIcon={
          isMultiSelect && (clicked || isClicked) && icon ? icon : undefined
        }
        onDelete={
          isMultiSelect && (clicked || isClicked) && icon
            ? () => handleClose()
            : undefined
        }
      />
    );
  }
);

export default CustomChip;
