'use client';
import { CmsImageProps, Sys } from '@bayada/interfaces';
import { useEffect, useRef, useState } from 'react';
import './donate-cta.scss';
import WrapperContainer, {
  getWrapperProps
} from '../wrapper-container/wrapper-container';

export interface DonateCtaProps {
  buttonText: string;
  formId: string;
  formType: string;
  source: string;
  icon?: CmsImageProps | null;
  sys?: Sys;
}

/**
 * Component to render the Donate CTA script.
 *
 * This component creates a <div> element and attaches a script to it.
 * The script is created with data attributes that are passed in as props.
 * The script is responsible for rendering the donate CTA.
 *
 * The script is added to the DOM with a 300ms timeout to prevent it from
 * being blocked by the browser's Content Security Policy (CSP).
 * If there is an existing script in the <div> element, it is removed before
 * adding the new script.
 */
export function DonateCta(props: DonateCtaProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scriptAppended, setScriptAppended] = useState(false);
  const { wrapperContainer } = getWrapperProps(props);

  // useEffect hook to add script to div element
  useEffect(() => {
    const script = document.createElement('script');
    if (containerRef && containerRef.current) {
      script.src = props?.source;
      script.setAttribute('data-btn-txt', props?.buttonText);
      script.setAttribute('data-form', props?.formId);
      script.setAttribute('data-form-type', props?.formType);
      setTimeout(() => {
        if (containerRef && containerRef.current) {
          containerRef.current.innerHTML = '';
          containerRef?.current?.appendChild(script);
          setScriptAppended(true);
        }
      }, 300);
    }
    return () => {
      script?.remove();
    };
  }, [
    props?.buttonText,
    props?.formId,
    props?.formType,
    props?.source,
    scriptAppended
  ]);

  return (
    <WrapperContainer className="mx-auto flex py-12" wrapper={wrapperContainer}>
      <div
        className="donate-cta-wrapper t-15 font-frutiger font-medium inline-flex empty:hidden"
        ref={containerRef}
      ></div>
    </WrapperContainer>
  );
}

export default DonateCta;
