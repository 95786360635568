/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { contentfulClient } from 'apps/bayada/config/contentful-client';
import { getUserAgent } from 'apps/bayada/utils/helper';
import { ContentfulClientApi } from 'contentful';
import React, { createContext, useState, useContext } from 'react';

// Define the context type
type AppContextType = {
  context: {
    ctfClient: ContentfulClientApi<undefined>;
    ctfConfig: any;
    constants?: any;
    userAgent?: string;
  };
  isProgressbarRequired: boolean;
  setData: React.Dispatch<React.SetStateAction<string>>;
  setIsProgressbarRequired: React.Dispatch<React.SetStateAction<boolean>>;
};

// Create the context with initial values
const AppContext = createContext<AppContextType | undefined>(undefined);

// Custom provider component
const AppContextProvider: React.FC<{
  children: React.ReactNode;
  ctfConfig: any;
  constants?: any;
  userAgent?: string;
}> = ({ children, ctfConfig, constants }) => {
  const { isEnabled, createClientParams } = ctfConfig;
  const ctfClient = contentfulClient(isEnabled, createClientParams);
  const userAgent = getUserAgent();

  const [context, setData] = useState<any>({
    ctfClient,
    ctfConfig,
    constants,
    userAgent
  });
  const [isProgressbarRequired, setIsProgressbarRequired] =
    useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        context,
        setData,
        isProgressbarRequired,
        setIsProgressbarRequired
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};

export { AppContext, AppContextProvider, useAppContext };
