'use client';
import React, { ComponentType, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

/**
 * The `withLazyLoad` function is a higher-order component in TypeScript React that enables lazy
 * loading of components when they come into view using the `react-intersection-observer` library.
 * @param WrappedComponent - The `WrappedComponent` parameter in the `withLazyLoad` function is the
 * component that you want to lazy load when it comes into view. This component will be rendered inside
 * the `div` element returned by the `withLazyLoad` function once it is in view.
 * @param [threshold=0.5] - The `threshold` parameter in the `withLazyLoad` higher-order component
 * function determines the percentage of the component that needs to be visible in the viewport before
 * triggering the lazy loading behavior. By default, it is set to 0.5, which means that at least 50% of
 * the component
 * @returns A Higher Order Component (HOC) called `withLazyLoad` is being returned. This HOC takes a
 * component as input and returns a new component that lazy loads the original component when it comes
 * into view based on a specified threshold.
 */
export const withLazyLoad = <P extends object>(
  WrappedComponent: ComponentType<P>,
  threshold = 0.1
): React.FC<P> => {
  return (props: P) => {
    const [ref, isInView] = useInView({
      threshold,
      triggerOnce: true,
      rootMargin: '40px 0px'
    });
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
      if (isInView && !hasLoaded) {
        setHasLoaded(true);
      }
    }, [isInView, hasLoaded]);
    return (
      <div ref={ref} className="component-load min-w-0 min-h-0">
        {hasLoaded ? <WrappedComponent ref={ref} {...props} /> : null}
      </div>
    );
  };
};
export default withLazyLoad;
