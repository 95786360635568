/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import React, {
  Children,
  cloneElement,
  isValidElement,
  useLayoutEffect,
  useState
} from 'react';
import { breakpoints } from '@bayada/assets';
import { AnimationProps } from 'framer-motion';

export type DisableAnimMobProps = {
  children?: React.ReactNode | React.ReactNode[];
};
/**
 * This component is useful for optimizing performance on mobile devices by disabling animations that might be resource-intensive or unnecessary on smaller screens.
 */
export const DisableAnimationsMobile: React.FC<DisableAnimMobProps> = ({
  children
}) => {
  const [deviceWidth, setDeviceWidth] = useState<number>(0);
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setDeviceWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(document.documentElement);
  }, []);

  if (deviceWidth > Number(breakpoints.lg)) {
    return children;
  }

  const modifiedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      const noAnimationProps = {
        animate: 'default',
        initial: undefined,
        exit: undefined,
        transition: { duration: 0 },
        variants: {
          default: {
            opacity: 1,
            top: 0,
            bottom: 0,
            scale: 1,
            transform: 'none'
          }
        }
      } as AnimationProps;

      return cloneElement(child, noAnimationProps);
    }
    return child;
  });

  return modifiedChildren;
};

export default DisableAnimationsMobile;
