import * as contentful from 'contentful';
import {
  ctfAccessToken,
  ctfEnvironment,
  ctfPreviewToken,
  ctfSpaceId
} from '../constants';

/**
 * Parameters for creating a Contentful client.
 *
 * @property {string | undefined} spaceId - The Contentful space ID.
 * @property {string | undefined} accessToken - The Contentful access token.
 * @property {string | undefined} previewToken - The Contentful preview token.
 * @property {string | undefined} environment - The Contentful environment.
 */
export type CreateClientParams = {
  spaceId: string | undefined; // The Contentful space ID.
  accessToken: string | undefined; // The Contentful access token.
  previewToken: string | undefined; // The Contentful preview token.
  environment: string | undefined; // The Contentful environment.
};

/**
 * The function `CreateClientParams` retrieves Contentful configuration parameters from environment
 * variables.
 */
export const CreateClientParams = (): CreateClientParams => ({
  spaceId: ctfSpaceId,
  accessToken: ctfAccessToken,
  previewToken: ctfPreviewToken,
  environment: ctfEnvironment
});

/**
 * The function `contentfulClient` creates a Contentful client with specified parameters for accessing
 * content from a Contentful space.
 * @param [isDraftMode=false] - The `isDraftMode` parameter is a boolean flag that indicates whether
 * the client should be set to work in draft mode or not. When set to `true`, the client will use the
 * preview token for accessing draft content in Contentful.
 * @param {CreateClientParams} clientParams - The `clientParams` object contains the following
 * properties:
 * @returns The `contentfulClient` function returns a Contentful client configured based on the
 * provided parameters. It creates a client with the specified space ID, access token (either preview
 * or regular based on draft mode), and optional environment. If draft mode is enabled, it sets the
 * host to 'preview.contentful.com'. The function returns the configured Contentful client.
 */
export const contentfulClient = (
  isDraftMode = false,
  clientParams: CreateClientParams = CreateClientParams()
) => {
  const {
    spaceId = '',
    accessToken = '',
    previewToken = '',
    environment
  } = clientParams;

  const config: contentful.CreateClientParams = {
    space: spaceId,
    accessToken: isDraftMode ? previewToken : accessToken
  };

  if (isDraftMode) {
    config.host = 'preview.contentful.com';
  }

  if (environment) {
    config.environment = environment;
  }

  return contentful.createClient(config);
};
