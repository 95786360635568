'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './cms-video.module.scss';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import { Icon } from '../icon/ba-icon';
import WrapperContainer from '../wrapper-container/wrapper-container';
import QumuPlayer from '../qumu-player/qumu-player';
import dynamic from 'next/dynamic';
import { CmsImageProps } from '@bayada/interfaces';

export interface CmsVideoProps {
  videoId?: string;
  videoUrl?: string;
  description?: string;
  videoType: 'youtube' | 'vimeo' | 'media' | 'qumu';
  placeholderImage?: CmsImageProps | null;
  media?: any;
  sysId?: string;
  internalName?: string;
  //style properties and React player controls
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  hideControl?: boolean;
  className?: string;
  volume?: number;
  controls?: number;
  pauseButton?: boolean;
}

const CmsImage = dynamic<CmsImageProps>(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import('@bayada/shared/ui-components').then((module) => module.CmsImage),
  {
    loading: () => <p>Loading...</p>
  }
);

/**
 * The CmsVideo component is a wrapper for the Qumu or ReactPlayer
 * component, depending on the `videoType` prop.
 *
 * The component uses React Player when the videoType is 'media'
 * or 'video'. In these cases, the component will use the `media`
 * prop to get the video URL. Otherwise, the component will use the
 * `videoUrl` prop directly.
 *
 * The component also renders a placeholder image before the video
 * is played using the `placeholderImage` prop. If the prop is not
 * provided, the placeholder image is not rendered.
 *
 * When a placeholder image is rendered, the component renders a
 * thumbnail with a play button over the top of it. The play button
 * is clickable and will trigger the video to play.
 *
 * The component accepts a number of props to customize the
 * appearance and behavior of the video player. See the propTypes
 * for a full list of options.
 */
export const CmsVideo: React.FC<CmsVideoProps> = (props: CmsVideoProps) => {
  const {
    videoUrl,
    videoType,
    placeholderImage,
    description,
    media,
    muted,
    loop,
    autoPlay,
    width,
    height,
    hideControl,
    className,
    controls,
    volume,
    pauseButton
  } = props || {};

  const [playerRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '8px 0px'
  });
  const url: string = videoType === 'media' ? media?.url : videoUrl;
  const [isClient, setIsClient] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(
    autoPlay || inView ? true : false
  );
  const wrapperContainer = (props as any)?.wrapperContainer;

  useEffect(() => {
    setIsClient(true);
  }, []);

  /**
   * Thumbnail is a component that renders a placeholder image and a play
   * button over the top of it. The play button is clickable and will trigger
   * the video to play. If no placeholder image is provided, Thumbnail will
   * not render anything.
   */
  const Thumbnail = () => {
    if (placeholderImage) {
      return (
        <div
          className={`absolute w-full h-full z-0 top-0 left-0 overflow-hidden focus:outline-1 focus-within:outline-1 outline-offset-1`}
        >
          <CmsImage
            altText={placeholderImage?.altText}
            ariaLabel={placeholderImage?.ariaLabel}
            imagelarge={placeholderImage?.imagelarge}
            imagemedium={placeholderImage?.imagemedium}
            imagesmall={placeholderImage?.imagesmall}
            className="object-cover !w-full !h-full"
          />
        </div>
      );
    }
  };

  const PlayIcon = () => {
    if (placeholderImage) {
      return (
        <div
          className={`absolute w-full h-full z-10 top-0 left-0 inline-flex items-center justify-center ${styles['video-thumb']}`}
        >
          <div className="relative w-full h-full">
            <div className="absolute h-full w-full z-0 flex items-center justify-center">
              <div className="flex h-16 w-16 justify-center items-center relative">
                <div className="blur-sm absolute w-full h-full z-0 rounded-full"></div>
                <div
                  tabIndex={0}
                  className={`flex items-center justify-center absolute w-full h-full z-10 rounded-full outline-offset-1 ${styles['play-icon-wrap']}`}
                >
                  <Icon
                    iconName="playButton"
                    className="svg-icon icon-32 flex items-center justify-center z-10 text-white"
                  />
                </div>
              </div>
            </div>
            <div
              className={`absolute z-10 bottom-0 px-4 py-3 w-full ${styles['text-bg-gradient']}`}
            >
              <h1
                className={`text-white t-18-21 !font-frutiger !font-bold ${styles['video-thumb-text']} line-height-[1.625rem]`}
              >
                {description}
              </h1>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const playerStatus = useCallback(() => {
    if (inView && !isVideoPlaying) {
      setIsVideoPlaying(true);
    }
  }, [inView, isVideoPlaying]);

  const pauseStatus = useCallback(() => {
    if (inView) {
      setIsVideoPlaying(false);
    }
  }, [inView]);

  useEffect(() => {
    if (pauseButton) {
      pauseStatus();
    } else {
      playerStatus();
    }
  }, [pauseButton, pauseStatus, playerStatus]);

  return videoType === 'qumu' ? (
    <WrapperContainer
      wrapper={wrapperContainer}
      className="relative mx-auto py-12"
    >
      <QumuPlayer
        width={width ? width : '100%'}
        height={height ? height : '100%'}
        url={url}
        autoPlay={autoPlay}
        controls={controls}
        loop={loop}
        volume={volume}
        pauseButton={pauseButton}
      />
    </WrapperContainer>
  ) : (
    isClient && (
      <WrapperContainer
        wrapper={wrapperContainer}
        className="relative mx-auto py-12"
      >
        <div className="w-full h-full min-w-0 flex" ref={playerRef}>
          <ReactPlayer
            url={url}
            controls={!hideControl}
            playing={inView ? isVideoPlaying : false}
            width={width ? width : '100%'}
            height={height ? height : '0px'}
            muted={autoPlay ? true : muted}
            playsinline
            volume={muted || autoPlay ? 0 : 100}
            tabIndex={-1}
            autoPlay={autoPlay}
            loop={loop}
            playIcon={<PlayIcon />}
            light={placeholderImage && !autoPlay ? <Thumbnail /> : false}
            className={`relative overflow-hidden pt-[56.25%] video-player focus:outline-1 focus-within:outline-1 outline-offset-1 $${className ?? ''}`}
            role="region"
            aria-label="video-player"
            previewTabIndex={-1}
            onPlay={playerStatus as any}
            onPause={pauseStatus as any}
          />
        </div>
      </WrapperContainer>
    )
  );
};
