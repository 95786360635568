/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

export type InputFieldProps = {
  fieldLabel: string;
  required: boolean;
  id: string;
  variant?: any;
  defaultValue: string;
  variantClass?: string;
  error?: boolean;
  className: string;
  helperText: string;
  multiline?: any;
  rows?: number;
};

/**
 * A custom input field component that wraps the MUI TextField.
 * It accepts several props that are passed directly to the TextField component.
 * This component is exported with the name "InputField"
 */
export const InputField: React.FC<InputFieldProps> = ({
  fieldLabel,
  required,
  id,
  variant,
  defaultValue,
  className,
  error,
  helperText,
  multiline,
  rows
}) => {
  return (
    <FormControl className={`app-input-field-control ${className ?? ''}`}>
      <TextField
        error={error}
        label={fieldLabel}
        required={required}
        id={id}
        defaultValue={defaultValue}
        className={`app-input-field ${className ?? ''}`}
        variant={variant}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
      />
    </FormControl>
  );
};
export default InputField;
