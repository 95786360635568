'use client';
import { Swiper, SwiperProps, SwiperRef } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import {
  Keyboard,
  Scrollbar,
  Navigation,
  Pagination,
  A11y
} from 'swiper/modules';
import PropTypes from 'prop-types';
/**
 * This Carousel component provides a reusable, customizable slider/carousel functionality with built-in keyboard support, scrollbar, navigation, pagination, and accessibility features.
 */
export const Carousel: React.FunctionComponent<
  React.RefAttributes<SwiperRef> & React.PropsWithChildren<SwiperProps>
> = ({ children, ...rest }): JSX.Element => {
  return (
    <Swiper
      {...rest}
      modules={[Keyboard, Scrollbar, Navigation, Pagination, A11y]}
    >
      {children}
    </Swiper>
  );
};
// prop type validation for the children
Carousel.propTypes = { children: PropTypes.node.isRequired };
export default Carousel;
