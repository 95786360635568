'use client';
import { PlayerSdk } from '@enghouse-qumu/player-sdk';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export type QumuPlayerProps = {
  width?: string;
  height?: string;
  url?: string;
  autoPlay?: boolean;
  volume?: number;
  loop?: boolean;
  controls?: number;
  pauseButton?: boolean;
};

/**
 * QumuPlayer component
 * This component is a wrapper for Qumu player
 * It takes width, height, and url as props
 * It then creates an iframe element inside a container
 * The iframe has a className of "absolute top-0 left-0 w-full h-full"
 * This means it will take up the full space of its parent container
 *
 * The component uses React useEffect hook to play the video when the component mounts
 * It grabs the iframe element using document.querySelector
 * Then it creates a new PlayerSdk instance passing in the iframe
 * Then it calls the play method on the sdk
 *
 * The above is all that the component does
 */
export function QumuPlayer(props: QumuPlayerProps) {
  const {
    width,
    height,
    url,
    autoPlay = false,
    loop = false,
    volume = 100,
    controls = 1,
    pauseButton
  } = props || {};
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const pauseRef = useRef(!autoPlay);
  const playerOptions = `autoPlay=${isInitialLoad ? autoPlay : false}&volume=${autoPlay ? 0 : volume}&allowfullscreen&seamless=true&loop=${loop}&controls=${controls}&embed=false&host=widgets&iframeName=kv-video-player-1-iframe-2941198&autoscale=true&AppProperties.PlayerShowEmbedButton=false`;

  const [playerRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  useEffect(() => {
    if (typeof pauseButton === 'boolean') {
      pauseRef.current = pauseButton;
    }
  }, [pauseButton]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;
    const sdk = new PlayerSdk(iframe);

    if (inView) {
      setIsInitialLoad(true);
      if (!pauseRef.current) {
        sdk.play();
      }
    } else {
      sdk.pause();
    }
    sdk.addEventListener('play', () => {
      if (inView) {
        pauseRef.current = false;
      }
    });
    sdk.addEventListener('pause', () => {
      if (inView) {
        pauseRef.current = true;
      }
    });
    return () => {
      sdk.removeEventListener('pause');
      sdk.removeEventListener('play');
    };
  }, [inView]);

  return (
    <div
      ref={playerRef}
      className={`relative h-0 pb-[56.25%] w-full flex overflow-hidden rounded-md`}
    >
      <iframe
        ref={iframeRef}
        seamless
        allowFullScreen={true}
        aria-hidden="true"
        title="qumu-player"
        name="qumu-player-bayada"
        width={width}
        height={height}
        className={`absolute top-0 left-0 w-full h-full focus:outline-1 focus-within:outline-1 outline-offset-2`}
        src={url ? `${url}?${playerOptions}` : ``}
      ></iframe>
    </div>
  );
}

export default QumuPlayer;
