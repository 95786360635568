import { GoogleMapApiKey, homePageUrl } from '../constants';
import { getLocLatLong } from '../services/office-service';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function composeCssClassName(str?: string) {
  if (str) {
    const className = str?.replace(
      /\.?([A-Z])/g,
      function (x: string, y: string) {
        return '-' + y.toLowerCase();
      }
    );
    return className;
  }
  return '';
}

export function uuidv4() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function isDevelopmentEnvironment() {
  const env = process.env.NODE_ENV;
  if (env == 'development') {
    return true;
  } else {
    return false;
  }
}

export function convertObjectToArray(obj: any): any[] {
  return Object.entries(obj ?? {}).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value?.map((element, index) => element);
    } else {
      return value;
    }
  });
}

export function camelizeString(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function isDateStringValid(dateString: string) {
  const date = new Date(dateString);
  return date.toString() !== 'Invalid Date';
}

export function formatDate(dateStr: string) {
  const isValidDate = isDateStringValid(dateStr);
  if (dateStr && isValidDate) {
    const date = new Date(dateStr);
    const month = date.toLocaleDateString('en-US', {
      month: 'long'
    });
    const year = date.toLocaleDateString('en-US', {
      year: 'numeric'
    });
    const day = date.toLocaleDateString('en-US', {
      day: '2-digit'
    });
    const dateFormatted = `${day}, ${month} ${year}`;
    return dateFormatted;
  } else {
    return '';
  }
}

export function isLocalOfficeLocationUrl(url: string): boolean {
  const urlParts = url?.split('/');
  // Check if the URL has at least two parts and the second part is 'locations'
  return urlParts?.length >= 2 && urlParts[1] === 'locations';
}

export function getPriorityItem(data: Array<any>, field: string) {
  if (!data || !field) {
    return null;
  }
  return data?.reduce(
    (min, p) => (p[`${field}`] < min[`${field}`] ? p : min),
    data[0]
  );
}

export function isValidUSPhoneNumber(phoneNumber: string): boolean {
  const phoneRegex = /^(?:\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
}

export function delayRender(promise: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, 300);
  }).then(() => promise);
}

// To get the nested slug of a contentful page (Parent page approach)
export const getNestedSlug = (obj: any) => {
  const slugs: string[] = []; // Array to store slugs
  function traverse(obj: any) {
    // If current object has slug, push it to the slugs array
    if (obj?.fields?.slug && obj?.fields?.slug !== homePageUrl) {
      slugs.push(obj?.fields?.slug);
    }
    // If current object has parentPage property, recursively call traverse function
    if (obj?.fields?.parentPage) {
      traverse(obj?.fields?.parentPage);
    }
  }
  traverse(obj);
  return slugs?.reverse()?.join('/');
};

export function getUserAgent() {
  if (typeof navigator !== 'undefined') {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
      // } else if (
      //   navigator.userAgent.indexOf('MSIE') != -1 ||
      //   !!document?.DOCUMENT_NODE == true
      // ) {
      //   //IF IE > 10
      //   return 'IE';
    } else {
      return 'unknown';
    }
  }
}

/* This function removes initial and trailing commas and spaces */
export const trimCommasAndSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim()?.replace(/^[,\s]+|[,\s]+$/g, '');
  }
  return '';
};

/* This function removes initial and trailing spaces */
export const trimSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim();
  }
  return '';
};

/**
 * Checks if an address string is valid for use in an autocomplete field.
 *
 * This function verifies if the provided address string is considered valid based on its length.
 * Specifically, it checks if the address is not just whitespace and contains at least one non-whitespace character.
 *
 * @param address - A string representing the address to be validated.
 *
 * @returns A boolean value:
 *   - `true` if the address string is not empty and contains more than just whitespace.
 *   - `false` if the address string is empty or consists only of whitespace.
 *
 * The function trims leading and trailing whitespace from the address before performing the length check.
 */
export const isAutoCompleteFieldValid = (address: string) => {
  if (address?.trim()?.length > 0) {
    return true;
  }
  return false;
};

/**
 * Constructs a URL for finding an office based on the provided location details.
 *
 * This function generates a URL to navigate to a "find an office" page. It determines the appropriate address to use based on whether a location was entered manually or a predefined address was selected. If a location is provided, it uses a geocoding service to retrieve the formatted address. If no location is entered, it uses the selected address directly. The resulting URL includes the address and optionally the selected service, formatted for use in a query string.
 *
 * @param enteredLoc - An optional string representing the manually entered location. If provided, this location is used to fetch the formatted address via a geocoding service.
 * @param selectedAddress - An optional string representing a predefined address. This is used directly if `enteredLoc` is not provided.
 * @param selectedService - An optional string representing the selected service to be included in the URL. If provided, it will be included in the query string.
 *
 * @returns A promise that resolves to a URL string for the "find an office" page. The URL includes:
 *   - `address`: The formatted address, sanitized for inclusion in a query string (spaces replaced with '+', and special characters removed).
 *   - `service`: The selected service, if provided, with spaces replaced with '+'.
 *
 * If neither `enteredLoc` nor `selectedAddress` is provided, the function will not generate a URL.
 *
 * Note: This function assumes that `getLocLatLong` is an asynchronous function that retrieves location details from a geocoding service.
 */
export const goToFindOffice = async (
  enteredLoc?: string | null,
  selectedAddress?: string,
  selectedService?: string
) => {
  let address;
  if (enteredLoc) {
    const response = await getLocLatLong(enteredLoc, GoogleMapApiKey);
    address = response?.results?.[0]?.formatted_address ?? null;
  } else {
    address = selectedAddress;
  }

  if (address) {
    const findOfficeUrl = `/find-an-office?address=${address?.replace(/[#\\/\s]/g, '+')}&service=${selectedService?.replace(/ /g, '+')}`;
    return findOfficeUrl;
  }
};
