/**
 * The `CtaButton` component in TypeScript React is a customizable button component that can display
 * text and an optional icon, handle different button types and sizes, and perform various actions like
 * opening a URL or downloading a file.
 * @param {any} url - The `url` parameter in the `CtaButtonProps` type represents the URL that the
 * button should navigate to when clicked. It is a string type and is used to specify the destination
 * URL for the button.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Button, SxProps, Theme } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  ButtonSize,
  ButtonType,
  CmsImageProps,
  IconPosition,
  Target
} from '@bayada/interfaces';
import { CmsImage } from '../cms-image/cms-image';
import { useRouter } from 'next/navigation';
import { Icon } from '../icon/ba-icon';

export type CtaButtonProps = {
  // urlReference?: string;
  target?: Target;
  buttonType: ButtonType;
  buttonSize?: ButtonSize;
  icon?: CmsImageProps | null;
  iconPosition?: IconPosition;
  buttonText?: string;
  ariaLabel: string;
  url?: string;
  file?: any;
  className?: string;
  hyperlinkClass?: string;
  sys?: any;
  onClick?: () => void;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
  htmlId?: string | undefined;
  textClass?: string;
  bgColor?: string;
  hasIcon?: boolean;
};

const iconDefaultWidth = 24;
const iconDefaultHeight = 24;

export function CtaButton(props: any) {
  const {
    target = 'same tab',
    buttonType = 'primary',
    buttonSize = 'regular',
    icon,
    iconPosition = 'after text',
    buttonText,
    url,
    className,
    ariaLabel,
    onClick,
    sx,
    file,
    hyperlinkClass,
    disabled,
    htmlId,
    textClass,
    bgColor,
    hasIcon
  } = props || {};
  const [onHover, setOnHover] = useState<boolean>(false);
  const router = useRouter();
  const style = {
    backgroundColor: bgColor
  };
  const isFirefox =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.indexOf('Firefox') !== -1;

  /* The `OnButtonClick` constant in the `CtaButton` component is a memoized callback function created
using the `useCallback` hook in React. It is responsible for handling the click event on the button.
Here's a breakdown of what it does: */
  const OnButtonClick = useCallback(() => {
    const _target = target === 'new tab' ? '_blank' : '_self';
    if (url && url.indexOf('#') === 0) {
      let newStr = url.substring(1);
      newStr = String(newStr)?.trim()?.replace(/\s/g, '-');
      if (window) {
        isFirefox
          ? (window.location.hash = `#${newStr}`)
          : router?.push(`#${newStr}`);
      }
    } else {
      if (file?.url) {
        fetchFile(file?.url);
      } else {
        if (onClick) {
          onClick();
        } else if (url) {
          isFirefox || _target === '_blank'
            ? window.open(url, _target)
            : router?.push(url);
        }
      }
    }
  }, [file?.url, isFirefox, onClick, router, target, url]);

  /**
   * The function `fetchFile` fetches a file from a given URL, creates a temporary URL for the file, and
   * initiates a download of the file.
   * @param {any} url - The `url` parameter in the `fetchFile` function is the URL of the file that you
   * want to fetch and download.
   */
  function fetchFile(url: any) {
    fetch(url)
      .then((res) => res.blob())
      .then((file) => {
        const tempUrl = URL.createObjectURL(file);
        const aTag = document.createElement('a');
        aTag.href = tempUrl;
        aTag.download = url.replace(/^.*[\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      });
  }

  /* The `ButtonIcon` constant in the `CtaButton` component is a memoized callback function created using
the `useCallback` hook in React. It is responsible for rendering an icon component based on the
provided `icon` prop and handling hover effects. */
  const ButtonIcon = useCallback(
    (width?: number, height?: number, className?: string) => {
      if (!icon || !icon?.imagelarge) {
        return null;
      }

      return (
        <CmsImage
          {...icon}
          ariaLabel={ariaLabel}
          className={`${className ? className : ''}`}
          height={height ?? iconDefaultHeight}
          width={width ?? iconDefaultWidth}
          toggleHover={onHover}
        />
      );
    },
    [ariaLabel, icon, onHover]
  );

  /**
   * The function `handleMouseEvents` sets the state `onHover` based on whether the mouse is over an
   * button or not.
   * @param {boolean} isMouseOver - The `isMouseOver` parameter in the `handleMouseEvents` function is a
   * boolean value that indicates whether the mouse is currently over the button or not. It is used to
   * update the state of `onHover` based on the mouse events.
   */
  const handleMouseEvents = (isMouseOver: boolean) => {
    setOnHover(isMouseOver);
  };

  /* This part of the code is the return statement of the `CtaButton` component. It is rendering a `div`
 element containing a `Button` component from the Material-UI library. 

 CAUTION: ADD BUTTON PROPERTIES WITH PROPER CONDITIONS, OTHERWISE IT WILL CREATE DISCREPANCIES.

 Here's a breakdown of what
 each part of the return statement is doing: */
  return (
    <div className={`${hyperlinkClass ?? ''}`}>
      <Button
        variant={
          buttonType === 'outlined'
            ? 'outlined'
            : buttonType === 'hyperlink'
              ? 'text'
              : 'contained'
        }
        color={buttonType === 'primary' ? 'primary' : 'secondary'}
        aria-label={ariaLabel}
        html-id={htmlId}
        tabIndex={0}
        sx={sx}
        style={style}
        className={`${className} ${buttonSize === 'small' ? 'button-small' : ''}`}
        startIcon={icon && iconPosition === 'before text' && ButtonIcon()}
        endIcon={
          icon && iconPosition === 'after text'
            ? ButtonIcon()
            : hasIcon && (
                <Icon
                  iconName="download"
                  className="svg-icon icon-24 moveRightIcon flex items-center justify-center"
                ></Icon>
              )
        }
        onClick={OnButtonClick}
        onMouseOver={() => handleMouseEvents(true)}
        onMouseOut={() => handleMouseEvents(false)}
        // ******* onPointerEnter, onPointerOut for touch events in mobiles/tab etc... ****
        onPointerEnter={() => handleMouseEvents(true)}
        onPointerOut={() => handleMouseEvents(false)}
        // ********************************************************************************
        disabled={disabled}
        disableFocusRipple
      >
        {iconPosition !== 'fill' && buttonText ? (
          <span
            className={`font-medium leading-snug font-frutiger ${textClass ? textClass : `t-15`}`}
          >
            {buttonText}
          </span>
        ) : (
          ButtonIcon()
        )}
      </Button>
    </div>
  );
}

export default CtaButton;
