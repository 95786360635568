import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Tooltip } from '@mui/material';
export interface StringCountUpProps {
  value: string;
  className?: string;
  playOnce?: boolean;
}

interface NumericValue {
  prefix: string;
  number: number;
  suffix: string;
}

/**
 * This component is used to display a string that contains numbers that should be animated with CountUp.js.
 * It takes a string as a prop and returns multiple h3 tags with CountUp components inside them.
 */
export function StringCountUp(props: StringCountUpProps) {
  const { value, className, playOnce = true } = props || {};

  // use a regex to find all numbers in the string.This regex matches any string that might contain a number, including commas and decimal points.
  const matches = value.matchAll(
    /\s*,\s*|([^\d.]*)?(\d+(,\d{3})*(\.\d+)?)([^\d.]*)?/g
  );

  const numericValues: NumericValue[] = [];
  const matchesArray = Array.from(matches);

  // use the useInView hook from the Intersection Observer API to determine when the component is visible in the viewport
  const [countUpRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: playOnce
  });

  for (const match of matchesArray) {
    const [, prefix, numberStr, , , suffix] = match;
    const number = parseFloat(numberStr.replace(/,/g, ''));

    if (!isNaN(number)) {
      numericValues.push({
        prefix: prefix || '',
        number,
        suffix: suffix || ''
      });
    }
  }
  return numericValues?.map((numericValue, index) => (
    <h3
      key={index}
      className={`${className ?? ''} cursor-default`}
      ref={countUpRef}
    >
      <Tooltip
        title={value}
        placement="bottom"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10]
                }
              }
            ]
          }
        }}
      >
        <span>
          <CountUp
            start={0}
            end={inView ? numericValue.number : 0}
            delay={0}
            separator={numericValue.number >= 10000 ? ',' : ''}
            decimals={Number.isInteger(numericValue.number) ? 0 : 2}
            prefix={numericValue.prefix}
            suffix={numericValue.suffix}
          />
        </span>
      </Tooltip>
    </h3>
  ));
}
