'use client';
import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';
import { CmsImageProps } from '@bayada/interfaces';
import { getResponsiveImage } from './cms-image-helper';
import { useDeviceSize } from '@bayada/hooks';
import { contentfulLoader } from '@delicious-simplicity/next-image-contentful-loader';
import withLazyLoad from '../lazy-load/lazy-load';

export type CmsImageState = {
  image: string;
  hoverImage?: string;
};

/**
 * The function `nextImageLoader` takes in an object with `src`, `width`, and `quality` properties and
 * returns a formatted URL string for loading images.
 * @param {ImageLoaderProps}  - The `nextImageLoader` function takes in an object with three
 * properties:
 * @returns The function `nextImageLoader` is returning a URL string that includes the `src`, `width`,
 * and `quality` parameters formatted in a specific way. The URL is constructed by appending the
 * `width` and `quality` parameters to the `src` with specific query parameters.
 */
// const nextImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
//   return `https:${src}?w=${width}&q=${quality || 75}`;
// };

/**
 * The `CmsImage` component in TypeScript React handles displaying images with responsive behavior and
 * hover effects based on device width and user interaction.
 * @param props - The `CmsImage` component accepts the following props:
 * @returns The `CmsImage` component is being returned. It takes in various props related to image
 * display and responsiveness, handles mouse hover events to toggle between regular and hover images,
 * and renders an `Image` component with the appropriate properties and styles based on the props
 * provided.
 */
export const CmsImage: React.FC<CmsImageProps> = (props) => {
  const {
    altText,
    ariaLabel,
    height,
    width,
    contentType,
    fill,
    style,
    wrapperContainer,
    wrapperClassName,
    toggleHover = false,
    imagelarge,
    imagemedium,
    imagesmall,
    hoverstatelarge,
    hoverstatemedium,
    hoverstatesmall,
    loading,
    priority,
    quality,
    ...rest
  } = props || {};

  const [deviceWidth] = useDeviceSize();
  const [onHover, setOnHover] = useState(toggleHover);
  const image: CmsImageState = getResponsiveImage(deviceWidth, props);

  const imageSrc = onHover
    ? image?.hoverImage
      ? image?.hoverImage
      : image?.image
    : image?.image;

  useEffect(() => {
    setOnHover(toggleHover);
  }, [toggleHover]);

  /**
   * The function `handleMouseEvents` sets the `onHover` state based on the `isMouseOver` parameter if
   * `toggleHover` is false and `image.hoverImage` is truthy.
   * @param {boolean} isMouseOver - The `isMouseOver` parameter is a boolean value that indicates
   * whether the mouse is currently over a specific element or not.
   */
  const handleMouseEvents = (isMouseOver: boolean) => {
    if (!toggleHover && image?.hoverImage) {
      setOnHover(isMouseOver);
    }
  };

  /* The `imageProps` constant in the `CmsImage` component is defining the properties that will be
 passed to the `Image` component from the `next/image` library. Here's a breakdown of what each
 property is doing: */
  const imageProps: ImageProps = {
    src: `https:${imageSrc}?q=1`,
    alt: altText ? altText : 'image-alt',
    'aria-label': ariaLabel ? ariaLabel : 'image-aria',
    className: '!text-white',
    //loader: nextImageLoader,
    style: {
      width: width ? `${width}px` : 'auto',
      height: height ? `${height}px` : 'auto',
      maxWidth: '100%',
      ...style
    },
    height: height || 0,
    width: width || 0,
    sizes: '100vw',
    // quality: quality ?? 75,
    priority: priority,
    loading: priority ? 'eager' : 'lazy'
  };

  // If the `fill` prop is truthy, we set the `fill` property of `imageProps` to the provided `fill` value.
  // Additionally, we extract the `width` and `height` properties from `style` (if they exist) and assign them to separate variables.
  // Finally, we assign the remaining properties of `style` to the `style` property of `imageProps`.
  // If `fill` is falsy, we simply assign `width` and `height` back to `imageProps`.
  if (fill) {
    imageProps.fill = fill;
    const { width, height, ...stylesWithoutWidthAndHeight } = style || {};
    // Assign the remaining properties of `style` to the `style` property of `imageProps`.
    imageProps.style = stylesWithoutWidthAndHeight;
  } else {
    // Assign `width` and `height` back to `imageProps`.
    imageProps.width = width || 0;
    imageProps.height = height || 0;
  }

  if (!imageProps?.src) return null;

  return (
    <Image
      itemProp="image"
      onMouseEnter={() => handleMouseEvents(true)}
      onMouseLeave={() => handleMouseEvents(false)}
      onPointerEnter={() => handleMouseEvents(true)}
      onPointerOut={() => handleMouseEvents(false)}
      loader={(props) => contentfulLoader(props, { fit: 'crop', ar: '1:1' })}
      {...imageProps}
      {...rest}
    />
  );
};
const LazyComponentWithLazyLoad = withLazyLoad(CmsImage, 0.5);
export default LazyComponentWithLazyLoad;
