import { CmsImageProps } from '@bayada/interfaces';
import { CmsImageState } from './cms-image';
import { breakpoints } from '@bayada/assets';

// to retrive the image and hover image based on the screen size if the images exisit
export const getResponsiveImage = (
  deviceWidth: number,
  image: CmsImageProps
): CmsImageState => {
  const imageLarge = image?.imagelarge;
  const imageMedium = image?.imagemedium ? image?.imagemedium : imageLarge;
  const imageSmall = image?.imagesmall ? image?.imagesmall : imageLarge;

  if (
    deviceWidth > Number(breakpoints.sm) &&
    deviceWidth < Number(breakpoints.lg)
  ) {
    return {
      image: imageMedium,
      hoverImage: image?.hoverstatemedium
    };
  } else if (deviceWidth <= Number(breakpoints.sm)) {
    return {
      image: imageSmall,
      hoverImage: image?.hoverstatesmall
    };
  }
  return {
    image: imageLarge,
    hoverImage: image?.hoverstatelarge
  };
};
