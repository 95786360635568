/**
 * This TypeScript React component defines a SeperatorBlock with customizable line width.
 * @property {string} lineWidth - The `lineWidth` property in the `SeperatorBlockProps` type specifies
 * the width of the separator line in the SeperatorBlock component. It is a string type property that
 * can have values like '50%', '75%', or any other value. The width of the separator line will be
 * determined
 * @property {Sys} sys - The `sys` property in the `SeperatorBlockProps` type is an optional property
 * of type `Sys`. It allows you to pass additional system-related data to the `SeperatorBlock`
 * component if needed. Since it is optional (denoted by the `?`), you can choose whether
 */
import { Sys } from '@bayada/interfaces';
import styles from './seperator-block.module.scss';

export type SeperatorBlockProps = {
  lineWidth: string;
  sys?: Sys;
};

export function SeperatorBlock(props: SeperatorBlockProps) {
  const { lineWidth } = props || {};
  return (
    <div
      className={`w-full flex`}
      role="separator"
      aria-label="seperator-block"
    >
      {lineWidth === '50%' ? (
        <div className={`${styles['app-seperator']} w-1/2`}></div>
      ) : lineWidth === '75%' ? (
        <div className={`${styles['app-seperator']} w-3/4`}></div>
      ) : (
        <div className={`${styles['app-seperator']} w-full`}></div>
      )}
    </div>
  );
}

export default SeperatorBlock;
