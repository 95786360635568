/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * The `ScrollToTop` component in TypeScript React scrolls the window to the top and sets the scroll
 * restoration to manual when rendered.
 * @returns The `ScrollToTop` component is returning `null`.
 */
'use client';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';

export const ResetScrollRestore: React.FC = () => {
  const router = useRouter();
  const handlePopState = () => {
    setTimeout(() => {
      if (window) {
        const hash: string = window?.location?.hash;
        if (hash) {
          const sectionId = hash.substring(1);
          const eleId = decodeURIComponent(sectionId);
          const section = eleId ? document?.getElementById(eleId) : null;
          if (section) {
            const isFirefox =
              typeof navigator !== 'undefined' &&
              navigator?.userAgent?.indexOf('Firefox') !== -1;
            if (isFirefox) {
              window.location.hash = hash;
            } else {
              router.push(hash);
            }
          }
        } else {
          window.scrollTo(0, 0);
        }
        if (history) {
          history.scrollRestoration = 'manual';
        }
      }
    }, 1000);
  };

  useEffect(() => {
    handlePopState();
    window?.addEventListener('popstate', handlePopState);
    return () => {
      window?.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    const eventData = {
      event: 'custom_event',
      category: 'Subscription',
      action: 'render',
      value: 1
    };
    const event = setTimeout(() => {
      sendGTMEvent(eventData);
    }, 1000);

    return () => {
      clearTimeout(event);
    };
  });
  return null;
};

export default ResetScrollRestore;
