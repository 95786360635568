import markdownit from 'markdown-it';
/**
 * This MarkDown function provides a way to convert markdown text to HTML, with a specific set of enabled rules.
 * It's designed to be flexible and can be easily imported and used in other parts of an application where markdown rendering is needed.
 * The function is particularly useful for rendering user-generated content or documentation that's stored in markdown format.
 */
export const MarkDown = (data: string) => {
  if (typeof data !== 'string') return '';
  const md = markdownit('zero', { linkify: true }).enable([
    'normalize',
    'block',
    'inline',
    'autolink',
    'link',
    'backticks',
    'emphasis',
    'paragraph',
    'text',
    'newline'
  ]);
  const result = md.render(data);

  return result;
};

export default MarkDown;
