/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { ICmsImage } from '@bayada/interfaces';
import { CmsImage } from '../cms-image/cms-image';
import { getSocialShareLinks } from './social-media-helper';
import { useCallback, useEffect, useState } from 'react';
import WrapperContainer from '../wrapper-container/wrapper-container';
import { useInView } from 'react-intersection-observer';
import { Link } from '@mui/material';

export type SocialMediaProps = {
  icon: ICmsImage | null;
  link?: string;
  ariaLabel?: string;
  sys?: any;
  platformName?: string;
  isShareBlock?: boolean;
};

/**
 * Component: SocialMedia
 *
 * This component renders a single social media link with a link to the
 * current page. The link can be a URL or a generated link depending on
 * the value of the `isShareBlock` prop.
 *
 * The component receives an `icon` prop which is a CMS image object. The
 * component renders the icon as an `<img>` tag using the `CmsImage` component.
 *
 * The `link` prop is a URL. If `isShareBlock` is true, the component will
 * generate a social media share link using the `platformName` prop. The
 * social media share link will be generated using the `getSocialShareLinks`
 * function.
 *
 * The component renders a Next.js Link component with the `link` prop.
 * The Link component is given a className, role, target, and aria-label.
 * The component also sets the tabIndex to 0.
 *
 * If the `isShareBlock` prop is true, the component renders the social media
 * link. If the `isShareBlock` prop is false, the component renders the
 * `link` prop.
 */
export function SocialMedia(props: SocialMediaProps) {
  const [currentPageUrl, setCurrentPageUrl] = useState('');

  const {
    icon,
    link = '',
    ariaLabel,
    platformName = '',
    isShareBlock = false
  } = props || {};

  useEffect(() => {
    if (window && window.location) {
      setCurrentPageUrl(window.location.href);
    }
  }, []);

  const [mediaRef, inView] = useInView({
    threshold: 0.01,
    triggerOnce: false,
    rootMargin: '8px 0px'
  });
  const isFirefox =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.indexOf('Firefox') !== -1;
  const wrapperContainer = (props as any)?.wrapperContainer;
  const socialShareLink =
    isShareBlock && currentPageUrl
      ? getSocialShareLinks(platformName, currentPageUrl)
      : '';

  const renderSocialMediaLink = useCallback(
    (link: string) => {
      return (
        <WrapperContainer
          wrapper={wrapperContainer}
          className="relative mx-auto py-1"
        >
          <div className="shrink-0" ref={mediaRef}>
            <Link
              href={link}
              role="link"
              target="_blank"
              className={`social-media-icon h-8 w-8 inline-flex rounded-full hover:scale-110 focus:scale-110 hover:shadow focus:shadow transition-transform will-change-transform delay-100 duration-300 ease-in-out ${inView ? 'scale-100' : 'scale-75'}`}
              aria-label={ariaLabel}
              tabIndex={0}
              onClick={(e) => {
                if (isFirefox) {
                  e?.preventDefault();
                  window.open(link, '_blank');
                }
              }}
            >
              {icon && (
                <CmsImage
                  className="object-contain"
                  width={32}
                  height={32}
                  {...icon}
                />
              )}
              <span className="sr-only m-0">{`link to ${platformName}`}</span>
            </Link>
          </div>
        </WrapperContainer>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ariaLabel, icon, platformName, wrapperContainer, inView, mediaRef]
  );

  return isShareBlock
    ? socialShareLink && renderSocialMediaLink(socialShareLink)
    : renderSocialMediaLink(link);
}

export default SocialMedia;
