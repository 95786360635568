import { Alignment, ICmsImage } from '@bayada/interfaces';
import { CmsImage } from '../cms-image/cms-image';
import Link from 'next/link';

export interface UserDetailsCardProps {
  id: number | string;
  image?: ICmsImage | null;
  name: string;
  designation: string;
  imageBorderRadius?: borderRadius;
  alignment?: Omit<Alignment, 'bottom'>;
  slug: string | null;
}

export type borderRadius = 'sm' | 'lg' | 'full';

/**
 * Get the class name for the border radius based on the input borderRadius string.
 * @param borderRadius The border radius string. It must be one of 'sm', 'lg', 'full' or undefined. If undefined, it returns 'rounded-none'.
 * @returns The class name for the border radius.
 */
function getBorderRadiusClass(borderRadius?: string): string {
  switch (borderRadius) {
    case 'sm':
      return 'rounded-sm';
    case 'lg':
      return 'rounded-lg';
    case 'full':
      return 'rounded-full';
    default:
      return 'rounded-none';
  }
}

/**
 * Component: UserDetailsCard
 *
 * A component to display an individual user's details in a card format
 *
 * Props:
 *
 * - `alignment`: the text alignment of the component. Accepted values are 'left', 'right' or 'center'.
 *   Defaults to 'center'.
 * - `slug`: the URL to link to on clicking the card
 * - `name`: the name of the user to display in the card
 * - `designation`: the role/designation of the user to display in the card
 * - `image`: the image object containing the image url and alt text of the user
 *   to display in the card. If not passed, a default image will be displayed
 *   instead.
 * - `imageBorderRadius`: the border radius of the image in the card. Accepted
 *   values are 'round', 'square', 'rounded-sm', 'rounded-md', 'rounded-lg',
 *   'rounded-xl' or 'rounded-full'. Defaults to 'rounded-full'.
 *
 */
export function UserDetailsCard(props: UserDetailsCardProps) {
  let textPositionCss = 'text-center';

  // Set the text alignment of the component based on the passed prop
  if (props?.alignment === 'left') {
    textPositionCss = 'text-left';
  } else if (props?.alignment === 'right') {
    textPositionCss = 'text-right';
  }
  const isFirefox =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.indexOf('Firefox') !== -1;
  return (
    <div
      className={`px-1 ${textPositionCss}  ${!props?.slug && 'pointer-events-none'}`}
    >
      <Link
        role="link"
        href={props?.slug ?? ':'}
        aria-label={`link to ${props?.name ?? 'View'} bio`}
        target="_top"
        aria-haspopup="false"
        aria-expanded="false"
        aria-disabled={props?.slug ? 'false' : 'true'}
        tabIndex={!props?.slug ? -1 : 0}
        onClick={(e) => {
          if (isFirefox) {
            e?.preventDefault();
            window.location.href = props?.slug ?? window.location.href;
          }
        }}
      >
        <figure className="mx-auto mb-[1.125rem] overflow-hidden rounded-full h-[6.25rem] w-[6.25rem]  sm:h-[10rem] sm:w-[10rem]">
          {props?.image && (
            <CmsImage
              imagelarge={props?.image ? props?.image?.imagelarge : ''}
              altText={props?.image?.altText}
              aria-label={props?.image?.ariaLabel}
              width={177}
              height={177}
              className={`mx-auto rounded-full will-change-transform !w-full !h-full transition-transform hover:scale-110 object-contain ${getBorderRadiusClass(props?.imageBorderRadius)}`}
            />
          )}
        </figure>
        <div className="text-center max-w-[15rem] mx-auto">
          <h3
            className="t-16-17 text-primary-black mb-1 font-semibold"
            aria-label="card-main-content"
          >
            {props?.name}
          </h3>
          <p
            className="t-13 font-normal color-ba-gray-900"
            aria-label="card-sub-content"
          >
            {props?.designation}
          </p>
        </div>
      </Link>
    </div>
  );
}
